import React, { Component } from 'react';
import {Card, TextStyle, TextContainer, Checkbox, Stack, TextField, Button, ButtonGroup, Avatar, Heading, Layout, Tag, PageActions, DataTable, RadioButton, Badge, Form, ExceptionList} from '@shopify/polaris';
import axios from '../Axios';
import PageTitleBar from '../Common/TitleBar';
import ToastMessage from '../Common/ToastMessage';
import SkeletonPageComp from './SkeletonPageComp';
import moment from "moment";
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

export default class CustomerDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_current_plan: this.props.user_current_plan,
            shop: this.props.shop,
            customer_id: this.props.match.params.id,
            page_loaded: false,
            customer: {},
            status: "",
            customer_tags: [],
            customer_tags_error: "",
            temp_customer_tags_value: "",
            approveBtnLoading: false,
            denyBtnLoading: false,
            deleteBtnLoading: false,
            created_at: null,
            last_updated_at: null,
            last_order_name: null,
            reason_for_decline: "Fill the valid data and resubmit the request",
            req_action_status: "approve",
            resubmission_allowed: true,
            resubmission_declined_reason: "",
            notify_customer: true,
            showToast: false,
            toastMsg: '',
            toastError: false,
            form_uid: null
        }
        axios.defaults.headers.common['X-Ws-Shop-Domain'] = "https://"+this.props.shop;
    }

    rebumitText = "Fill the valid data and resubmit the request";

    blockText = "Violation of terms and privacy policy";

    showToastMessage(msg, error){
    	this.setState({
    	  showToast: true,
    	  toastMsg: msg,
    	  toastError: error||false,
    	});
    }

    dissmissToast = () =>{
    	this.setState({
    	  showToast: false,
    	});
    }

    componentDidMount() {
        if(this.props.shop){
            this.getCustomerDetails();
        }
        else{
            this.setState({page_loaded: true});
        }
    }

    getCustomerDetails(){
        if(this.props.getFromShopify){
            axios.get('/customer/from-shopify/detail/'+ this.state.customer_id +'/'+ this.props.shop).then(r=>{
                if(r && r.data && r.data.length !== 0){
                    var { customer, status, tags,created_at,last_updated_at,last_order_name} = r.data;
                    var customer_tags = [];
                    var customer_tags_error = "Please enter at least one customer tag"; // default error message on component load
                    if(tags && tags.length > 0){
                        customer_tags = tags.replace(new RegExp(", ", "g"), ",").split(",");
                        customer_tags_error = "";
                    }
                    if(customer){
                        this.setState({
                            customer_tags, customer_tags_error, customer,status,tags,page_loaded:true,created_at,last_updated_at,last_order_name
                        });
                    }
                }
                else{
                    this.flashMessage("You already approved or rejected the account approval request.", true);
                    this.props.history.push(BUILD_VERSION_EXTENSION+'/customers/wholesale_approval');
                }
                this.setState({page_loaded: true});
            }).catch(e=>{
                this.flashMessage("Server error", true);
                this.setState({page_loaded: true});
                this.props.history.push(BUILD_VERSION_EXTENSION+'/customers/wholesale_approval');
            })
        }
        else{
            axios.get("/admin/api/2021-03/customers?shop="+this.props.shop+"&customer_id="+this.props.match.params.id).then(r=>{
                if(r && r.data && r.data.length !== 0){
                    var { customer, form_uid, status, account_tags, created_at, updated_at, reason_for_decline } = r.data;
                    var customer_tags_error = "Please enter at least one customer tag"; // default error message on component load
                    try {
                        account_tags = account_tags.split(",");
                        account_tags = account_tags.filter( x => x !== "");
                    } catch (error) {
                        
                    }
                    try {
                        account_tags = account_tags.filter( x => x.trim() !== "");
                    } catch (error) {
                        
                    }
                    if(account_tags.length > 0) customer_tags_error = "";
                    
                    let declineReason = '';
                    if(typeof r.data.__v !== "undefined" && r.data.__v && r.data.__v > 0){
                        // means more than 1 action performed like rejected, resubmit
                        if(reason_for_decline !== "Invalid data"){
                            // Filter out default value of raeason
                            declineReason = reason_for_decline;
                        }
                    }

                    if(customer){
                        this.setState({
                            customer_tags_error: customer_tags_error,
                            form_uid: form_uid,
                            customer,
                            status,
                            customer_tags: account_tags,
                            tags: account_tags,
                            page_loaded:true,
                            created_at,
                            resubmission_declined_reason: declineReason,
                            last_updated_at: updated_at
                        });
                    }
                }
                else{
                    this.flashMessage("You already approved or rejected the account approval request.", true);
                    this.props.history.push(BUILD_VERSION_EXTENSION+'/customers/wholesale_approval');
                }
                this.setState({page_loaded: true});
            }).catch(e=>{
                this.flashMessage("Server error", true);
                this.setState({page_loaded: true});
                this.props.history.push(BUILD_VERSION_EXTENSION+'/customers/wholesale_approval');
            })
        }
    }

    flashMessage(message, error) {
        this.showToastMessage(message,error );
    }

    renderTags(){
        var customer_tags = this.state.customer_tags;
        return customer_tags.map((tag,i) => {
            return <span key={i}>
                <Tag
                    onRemove={this.removeTag.bind(this,tag,i)}
                >{tag}</Tag>
            </span>
        });
    }

    removeTag(tag,i,el){
        var customer_tags = this.state.customer_tags;
        customer_tags.splice([i],1);
        this.setState({ customer_tags });
    }

    deleteAccount(){
        if(window.confirm(`Delete ${this.state.customer.first_name}?`)){
            this.setState({deleteBtnLoading: true});
            axios.delete('/customer/api/335/admin/delete?customer_id='+ this.state.customer_id +'&shop='+ this.props.shop).then(r=>{
                if(r && r.data){
                    if( r.data.status === 1){
                        this.flashMessage(r.data.message, false);
                        this.props.history.push(BUILD_VERSION_EXTENSION+'/customers/wholesale_approval');
                    }
                    else{
                        this.flashMessage("Server error. Rrefresh page and try again", true);
                    }
                }
                else{
                    this.flashMessage("Server error. Rrefresh page and try again", true);
                }
                this.setState({deleteBtnLoading: false});
            }).catch(e=>{
                this.flashMessage("Server error", true);
                this.setState({deleteBtnLoading: false});
            })
        }
    }

    validateApproveCustomer(){
        let isValid = true;
        
        this.setState({ customer_tags_error: ""}); // remove errors
        if(this.state.customer_tags.length === 0){
            isValid = false;
            this.setState({
                customer_tags_error: "Please enter at least one customer tag"
            });
        }

        return isValid;
    }

    approveCustomer(){

        if(!this.validateApproveCustomer())return; // don't proceed if some error found


        this.setState({approveBtnLoading: true});
        var item = {
            id: this.props.match.params.id,
            customer_id: this.props.match.params.id,
            shop: this.props.shop,
            tags: this.state.customer_tags.toString(),
            tax_exempt: this.state.customer.tax_exempt,
            form_uid: this.state.form_uid
        };
        axios.post('/admin/api/2021-03/approve', item).then(response => {
            if(response && response.data &&  response.data.code === 1){
                if(response.data.message){
                    this.flashMessage(response.data.message);
                }
                this.props.history.push(BUILD_VERSION_EXTENSION+'/customers/wholesale_approval');
            }
            else if(response && response.data &&  response.data.code === 0){
                this.flashMessage(response.data.message, true);  
            }
            this.setState({approveBtnLoading: false});
        }).catch(e => {
            this.flashMessage("Server error",true);
            this.setState({approveBtnLoading: false});
        });
    }

    denyCustomer(){
        this.setState({denyBtnLoading: true});
        var item = {
            _id: this.props.match.params.id,
            shop: this.props.shop,
            reason_for_decline: this.state.reason_for_decline || "No reason provided by shop owner",
            resubmission_allowed: this.state.resubmission_allowed,
            notify_customer: this.state.notify_customer
        };
        axios.post('/admin/api/2021-03/reject', item).then(response => {
            if(response && response.data && response.data.code === 1){
                this.flashMessage(response.data.message);
                this.props.history.push(BUILD_VERSION_EXTENSION+'/customers/wholesale_approval');
            }
            else if(response.data.message){
                this.flashMessage(response.data.message,true);
            }
            this.setState({denyBtnLoading: false});
        }).catch(e => {
            this.flashMessage("Server error",true);
            this.setState({denyBtnLoading: false});
        });
    }

    renderUploadedFiles(el, uploadedKeys){
        var html = null;
        var isIndex = uploadedKeys.findIndex(x => x.key === el.key);
        if(-1 < isIndex){
            var values = uploadedKeys[isIndex].values;
            if(values && values.length > 0){
                try{
                    html = values.map((v,i) => {
                        return <span key={'ke'+i}><a className="ws--download-file" target="_new" href={v}>Download file</a>{((i+1)%3 === 0) && <br></br>}</span>
                    });
                }catch(e){

                }
            }
        }
        return html;
    }

    renderDataTime(msg, d){
        try {
            d = moment(d).format('LLL');
        } catch (error) { }
        return <div><TextStyle variation="strong">{msg}</TextStyle>{d}</div>
    }

    bannerTitle(){
        var status = this.state.status;
        var customer = this.state.customer;
        if("request_an_account" === status.toLowerCase()){
            status = " request for a wholesale account";
        }
        else if("account_created" === status.toLowerCase()){
            status = "'s account was created";
        }
        else if("account_rejected" === status.toLowerCase()){
            status = "'s request was declined alread";
        }
        else if("rejected" === status.toLowerCase()){
            status = "'s request was declined";
        }
        else{
            status = " "+status;
        }
        if(customer){
            return customer.first_name +""+ status;
        }else{
            return "";
        }
    }

    addCustomerTags() {
        /* Fetch tags from state */
        var temp_customer_tags_value = this.state.temp_customer_tags_value;
        try{
            temp_customer_tags_value = temp_customer_tags_value.replace(/,/gi, "");
        }catch(e){}
        if(temp_customer_tags_value.trim() === "") return;

        /* Check if tags are not already exists */
        var customer_tags = this.state.customer_tags;
        if(customer_tags.some(tag=>tag.toLowerCase() === temp_customer_tags_value.toLowerCase())){
            this.flashMessage("Tag already exists",true);
            return false;
        }

        /* Update tags */
        customer_tags.push(temp_customer_tags_value);
        this.setState({ customer_tags, temp_customer_tags_value: "" });
    }

    render() {

        const { req_action_status, customer, status, customer_tags, temp_customer_tags_value, created_at, last_updated_at } = this.state;

        var extra_info_rows = [];

        var extFArray = [];
        var uploadedFiles = [];
        if(customer){
            for (let [key, value] of Object.entries(customer)) {
                if("first_name" === key || "last_name" === key || "email" === key || "password" === key || "password_confirmation" === key || key === "customer_locale") continue;
                if("first_name" === key || "last_name" === key || "email" === key || "password" === key || "password_confirmation" === key || key === "customer_locale") continue;
                if("note" === key) continue;
                if(key && (key.toLowerCase() === "password_confirmation")){
                    continue;
                }
                if(key && key.toLowerCase() === "password"){
                    extFArray.push({
                        "key" : key,
                        "value": "********"
                    });
                }
                else if(key === "addresses"){
                    if(value[0]){
                        for (let [k, v] of Object.entries(value[0])) {
                            extFArray.push({
                                "key" : k,
                                "value": v
                            });
                        }
                    }
                }
                else if(key === "address"){
                    if(value){
                        for (let [k, v] of Object.entries(value)) {
                            extFArray.push({
                                "key" : k,
                                "value": v
                            });
                        }
                    }
                }
                else if(typeof value === "string"){
                    var isFileUploaded = -1;
                    try{
                        if((value.toString().indexOf("https://digitalcoo.sfo2.digitaloceanspaces.com") > -1)||(value.toString().indexOf("cdn.shopify.com") > -1)){
                            isFileUploaded = 1;
                        }
                    }catch(e){
                        
                    }
                    if(isFileUploaded > -1){
                        extFArray.push({
                            "key" : key,
                            "value": value
                        });
                        try{
                            uploadedFiles.push({
                                values: value.split(","),
                                key: key
                            });
                        }catch(e){
                        
                        }
                    }
                    else{
                        extFArray.push({
                            "key" : key,
                            "value": value==="true"?"Yes":value==="false"?"No":value===true?"Yes":value===false?"No":value
                        });
                    }
                } 
            }
        }

        if(extFArray.length > 0){
            extra_info_rows = extFArray.map((el,i) => [
                <div className="text-capitalize">{el.key.replace(new RegExp("_","g"), " ")}</div>,
                <div className="" style={{whiteSpace:'pre-wrap'}}>
                    {
                        ((el.value && el.value.toString().indexOf("https://digitalcoo.sfo2.digitaloceanspaces.com") > -1) || (el.value && el.value.toString().indexOf("cdn.shopify.com") > -1)) ?
                        <span>
                            {this.renderUploadedFiles(el,uploadedFiles)}
                            <span className="ws--download-file-note">Note, download link is valid for next 30 days</span>
                        </span>
                        :el.value
                    }
                </div>,
            ])
        }
        var cusAction = null;
        var cusTitle = null;
        if(this.state.customer_id && this.props.getFromShopify){
            cusAction = [{content: "View more details", external:true, url: `https://${this.props.shop}/admin/customers/${this.state.customer_id}`}];
            cusTitle = "Customer information";
        }
        const customerDetailsSection = <Card title={cusTitle}
            actions={cusAction}
        >
            <Card.Section>
            <Stack spacing="tight">
                    <Avatar customer name={customer.first_name + " " + customer.last_name } />
                    <div>
                        <Heading>
                            {customer.first_name + " " + customer.last_name }
                        </Heading>
                        <TextContainer>
                            <span className="text-capitalize">{
                                status && status.replace(/_/g," ")
                            }</span>
                        </TextContainer>
                    </div>
                </Stack>
                {this.state.resubmission_declined_reason !== "" &&

                    <div style={{marginTop: "20px"}}>
                        <Stack spacing="tight">
                            <TextStyle variation="strong">
                                {status && status === "resubmit" ? "Decline Reason:" : "Last Decline Reason:"}
                            </TextStyle>
                            <pre style={{margin:"0", whiteSpace:'pre-wrap'}}>{this.state.resubmission_declined_reason}</pre>
                        </Stack>
                    </div>

                }
                {
                    this.props.getFromShopify && <div>
                        <div style={{marginTop: "2rem"}}></div>
                        <Stack vertical>
                            <TextField
                                label="Customer Note"
                                placeholder="Add a note"
                                rows="12"
                                multiline
                                value={customer.note}
                                onChange={(v) => {
                                    var customer = this.state.customer;
                                    customer.note = v;
                                    this.setState({customer})
                                }}
                            />
                        </Stack>
                    </div>
                }
            </Card.Section>
            {
                extFArray.length > 0 ?
                <Card.Section>
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                        ]}
                        headings={[
                            'Label',
                            'Value',
                        ]}
                        rows={extra_info_rows}
                        verticalAlign="middle"
                    ></DataTable>
                </Card.Section>
                :null
            }
        </Card>;

const approveSection = <>
<div className="wsaio_discount_grid">
    <div className="wsaio_g_left">
        <Stack vertical spacing="tight">

            {/* Tag input */}
            <Stack.Item>
                <Form onSubmit={() => {
                    this.addCustomerTags();
                }}>
                    <TextField
                        autoComplete={false}
                        type="text"
                        id="customer-tag-field"
                        error={this.state.customer_tags_error}
                        label="Add tags to the customer once approved"
                        placeholder="e.g wholesale"
                        value={temp_customer_tags_value}
                        onChange={(v) => {
                            this.setState({temp_customer_tags_value:v, customer_tags_error: ""});
                        }}
                        connectedRight={<Button primary
                            disabled={!temp_customer_tags_value}
                            onClick={() => {
                                this.addCustomerTags();
                            }}
                        >
                            Add Tag
                        </Button>}
                    />
                </Form>
            </Stack.Item>

            {/* Tags */}
            {
                customer_tags && customer_tags.length > 0 &&
                <Stack.Item>
                    <Stack spacing="tight">{this.renderTags()}</Stack>
                </Stack.Item>
            }

            {/* tax exempt input */}
            <Stack.Item>

                <div style={{borderTop:'0.1rem solid rgb(225 227 229)',marginTop:'15px'}}></div>
            </Stack.Item>
            <Stack.Item>
                <Checkbox
                    label="Don't charge sales tax on the orders for this customer"
                    checked={customer.tax_exempt === "true"}
                    onChange={(v) => {
                        var customer = this.state.customer;
                        customer.tax_exempt = v?"true":"false";
                        this.setState({customer});
                    }}
                ></Checkbox>
            </Stack.Item>

            {/* Submit button */}
            <Stack.Item>
                <ButtonGroup>
                    <Button
                        primary
                        onClick={() => {this.approveCustomer();}}
                        loading={this.state.approveBtnLoading}
                    >
                    {
                        this.props.getFromShopify? "Add/Update Tags":"Approve customer"
                    }
                    </Button>
                </ButtonGroup>
            </Stack.Item>
            {
                this.props.getFromShopify &&
                <Stack.Item>
                    <TextStyle variation="subdued">
                        Note: You can only add/update tags for this customer.
                    </TextStyle>
                </Stack.Item>
            }


        </Stack>
    </div>
    <div className="wsaio_g_right">

                <ExceptionList
                    items={[
                        {
                            description: 'Tags are case-insensitive, the uppercase and lowercase letters of tags are treated as equivalent. For instance, the tags "wholesale", "Wholesale" and "WHOLESALE" are of the same significance.',
                        },
                        {
                            description: 'You can add multiple tags to the customer',
                        },
                    ]}
                />
            
            </div>
        </div>
        </>

        var declineSection = <div>
            <Stack vertical spacing="tight">
                <RadioButton
                    label="Allow this customer to resubmit the wholesale account request"
                    helpText="When this is checked, customer will be able to resubmit the request"
                    checked={this.state.resubmission_allowed}
                    onChange={(v) => {
                        var reason_for_decline = this.rebumitText;
                        this.setState({resubmission_allowed:true,reason_for_decline})
                    }}
                />
                <RadioButton
                    label="Parmanent block this customer"
                    helpText="When this is checked, customer will not able to create a wholesale account"
                    checked={!this.state.resubmission_allowed}
                    onChange={(v) => {
                        var reason_for_decline = this.blockText;
                        this.setState({resubmission_allowed:false,reason_for_decline})
                    }}
                />
                <Checkbox
                    label="Notify this customer that your account denied for the specific reason"
                    helpText="When this is checked, customer will receive an email"
                    checked={this.state.notify_customer}
                    onChange={(v) => {
                        this.setState({notify_customer:v})
                    }}
                />
                {
                    (this.state.notify_customer) && <TextField
                        label={"Reason for "+(this.state.resubmission_allowed ? "decline" : "block")}
                        multiline={3}
                        value={this.state.reason_for_decline}
                        onChange={(v) => { this.setState({reason_for_decline:v}) }}
                    />
                }
                <Button
                    destructive
                    disabled={this.state.reason_for_decline.trim() === ""}
                    onClick={() => {
                        this.denyCustomer();
                    }}
                    loading={this.state.denyBtnLoading}
                >
                    {
                        this.state.resubmission_allowed ? "Decline" : "Block"
                    }
                </Button>
            </Stack>
        </div>;

        var toast = this.state.showToast? (
            <ToastMessage
              onDismiss={this.dissmissToast}
              message={this.state.toastMsg}
              error={this.state.toastError}
            />
          ): (null);


        if(this.state.page_loaded){
            return (
                <div>
                    <PageTitleBar
                        title="Customer Detail"
                        breadcrumbs={[{content: 'Customers', url: BUILD_VERSION_EXTENSION+'/customers/wholesale_approval', target: 'APP'}]}
                        secondaryActions={[{content: 'Back', url: BUILD_VERSION_EXTENSION+'/customers/wholesale_approval', target: 'APP'}]}
                    />
                    {toast}
                    <Layout>
                        <Layout.Section>
                            <Card>
                                <Card.Section>
                                    <Stack>
                                        <RadioButton
                                            label="Review and approve customer account request"
                                            checked={"approve" === req_action_status}
                                            id="approve"
                                            onChange={(v,id) => { this.setState({req_action_status:"approve"}) }}
                                        />
                                        {
                                            (!this.props.getFromShopify) && 
                                            <RadioButton
                                                label="Decline customer account request"
                                                checked={"decline" === req_action_status}
                                                id="decline"
                                                onChange={(v,id) => { this.setState({req_action_status:"decline"}) }}
                                            />
                                        }
                                    </Stack>
                                </Card.Section>
                                <Card.Section>
                                {
                                    (req_action_status === "approve") && approveSection
                                }
                                {
                                    (req_action_status === "decline") && declineSection
                                }
                                </Card.Section>
                            </Card>
                        </Layout.Section>
                        <Layout.Section>
                            {customerDetailsSection}
                        </Layout.Section>
                        <Layout.Section secondary>
                            <Card title="Customer overview" sectioned>
                                <Stack vertical spacing="tight">
                                    <div>{customer.email}</div>
                                    {
                                        (customer.accepts_marketing === "true" || customer.accepts_marketing === true)?
                                        <Badge status="success">Accepts email marketing</Badge>
                                        :<Badge>Not accepting email marketing</Badge>
                                    }
                                    {
                                        (last_updated_at) && 
                                        <div>
                                            {this.renderDataTime("Last updated: ",last_updated_at)}
                                        </div>
                                    }
                                    {
                                        (created_at) &&
                                        <div>
                                            {this.renderDataTime("Registered: ",created_at)}
                                        </div>
                                    }
                                </Stack>
                            </Card>
                        </Layout.Section>
                    </Layout>
                    <div style={{marginTop: "2rem"}}></div>
                    {
                        !this.props.getFromShopify && <PageActions
                            primaryAction={null}
                            secondaryActions={[
                                {
                                    content: 'Delete customer request',
                                    destructive: true,
                                    onAction: () => { this.deleteAccount(); },
                                    disabled: this.state.deleteBtnLoading
                                },
                            ]}
                        />
                    }
                </div>
            );
        }
        else{
            return (
                <div>
                    <PageTitleBar
                        title="Customer Detail"
                        breadcrumbs={[{content: 'Customers', url: BUILD_VERSION_EXTENSION+'/customers/wholesale_approval', target: 'APP'}]}
                        secondaryActions={[{content: 'Back', url: BUILD_VERSION_EXTENSION+'/customers/wholesale_approval', target: 'APP'}]}
                    />
                    {toast}
                    <SkeletonPageComp />
                </div>
            );
        }
    }
}